.draggable {
    
   
    cursor: grab;
   
  }
  
  .dragging {
    background-color: #00005A;
    cursor: grabbing;
  }